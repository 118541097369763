import React, {
  Component
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from './firebaseConfig';
import {
  CheckoutForm,
  Switch
} from './elements';
import {
  Elements,
  StripeProvider
} from 'react-stripe-elements';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './App.css';

var moment = require('moment');

const fb = firebase.initializeApp(firebaseConfig);
var db = fb.firestore();

class App extends Component {
  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const refersionLink = urlParams.get('rfsn');

    this.state = {
      step: 0,
      hadBaby: false,
      isPregnant: false,
      isTTC: false,
      phone: '',
      plan: 'annually',
      plans: {
        monthly: {
          price: 19.99
        },
        quarterly: {
          price: 49.99,
          monthly: 16.67
        },
        annually: {
          price: 119.99,
          monthly: 9.99
        }
      },
      firstName: '',
      lastName: '',
      loading: true,
      email: '',
      error: false,
      errorMessage: 'There was an error. Please try again.',
      date: null,
      phoneConfirmation: null,
      verificationCode: '',
      coupon: refersionLink ? 'FRIEND10' : '',
      couponApplied: false,
      uid: null,
      user: null,
      subscriptionCancelled: false,
      login: (window.location.href.indexOf('login') > 0),
      previousSubscription: false,
      cancelledSubscription: false,
      upgrade: false,
    };
  }

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
      'size': 'invisible',
      'callback': (response) => {

      }
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.setState({
          user: null,
          loading: false,
          step: this.state.login ? 2 : 1,
        });

        return;
      }

      db.collection('users').doc(user.uid).get()
        .then((doc) => {

          let data = doc.data();

          let step = this.state.login ? 2 : 1;

          if (!data.subscription) {
            step = 4;
          } else {
            step = 6;
          }

          this.setState({
            uid: user.uid,
            userData: data,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            loading: false,
            step: this.state.step ? this.state.step : step,
          });
        })
        .catch((error) => {
          this.setState({
            user: null,
            loading: false,
            step: this.state.login ? 2 : 1,
          });
        });
    });
  }

  _upgradeOnClick = (event) => {
    this.setState({
      step: 4,
      upgrade: true,
    });

    if (this.state.coupon) {
      this._applyCouponOnClick();
    }
  }

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  _hadBabyOnClick = (isChecked) => {
    this.setState({
      hadBaby: isChecked,
      isPregnant: false,
      isTTC: false
    });
  }

  _pregnantOnClick = (isChecked) => {
    this.setState({
      hadBaby: false,
      isPregnant: isChecked,
      isTTC: false
    });
  }

  _ttcOnClick = (isChecked) => {
    this.setState({
      hadBaby: false,
      isPregnant: false,
      isTTC: isChecked
    });
  }

  _infoOnClick = (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      hadBaby,
      isPregnant,
      isTTC,
      date
    } = this.state;

    db.collection('leads').doc(email).set({
      firstName: firstName,
      lastName: lastName,
      email: email,
      dueDate: isPregnant ? new Date(date) : null,
      babyBirthday: hadBaby ? new Date(date) : null,
      tryingToConceive: isTTC ? new Date() : null,
      createdAt: new Date(),
    })
      .then(() => {
        this.setState({
          step: 2,
          error: false
        });
      })
      .catch((error) => {
        this.setState({
          step: 2,
          error: false
        });
      });
  }

  _phoneNumberOnChange = (event) => {
    this.setState({
      phone: event
    });
  }

  _phoneNumberOnSubmit = (event) => {
    event.preventDefault();

    firebase.auth().signInWithPhoneNumber('+' + this.state.phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        this.setState({
          step: 3,
          phoneConfirmation: confirmationResult,
          error: false
        });
      }).catch((error) => {
        window.recaptchaVerifier.render().then(widgetId => {
          window.recaptchaVerifier.reset(widgetId);
        });
        this.setState({
          error: true
        });
      });
  }

  _verificationCodeOnSubmit = (event) => {
    event.preventDefault();

    const {
      phoneConfirmation,
      verificationCode
    } = this.state;

    phoneConfirmation.confirm(verificationCode).then(async (result) => {
      let user = result.user;

      let userRecord = await db.collection('users').doc(user.uid).get();
      let data = undefined;

      if (userRecord) {
        data = userRecord.data();
      }

      let step = 4;

      if (data) {
        if (data.subscription) {
          if (!data.subscription.cancelledAt) {
            step = 6;
            this.setState({
              uid: user.uid,
              userData: data,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              error: false
            });
          } else {
            this.setState({
              cancelledSubscription: true,
            });
          }
        } else {
          this.setState({
            uid: user.uid,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            error: false
          });
        }
      }

      const {
        firstName,
        lastName,
        email,
        hadBaby,
        isTTC,
        isPregnant,
        date,
        phone
      } = this.state;

      if (email) {
        db.collection('users').doc(user.uid).set({
          firstName: firstName,
          lastName: lastName,
          email: email,
          dueDate: isPregnant ? new Date(date) : null,
          babyBirthday: hadBaby ? new Date(date) : null,
          tryingToConceive: isTTC,
          tryingToConceiveStartedAt: isTTC ? new Date() : null,
          uid: user.uid,
          createdAt: new Date(),
          updatedAt: new Date(),
          phoneNumber: phone,
          postnatalMedicalClearanceAcceptedOn: null,
          prenatalMedicalClearanceAcceptedOn: null,
          reportedLossOn: null
        }, {
          merge: true
        })
          .then(() => {
            this.setState({
              uid: user.uid,
              step: step,
              error: false
            });
          })
          .catch((error) => {
            this.setState({
              error: true
            });
          });
      } else {
        this.setState({
          uid: user.uid,
          step: step,
          error: false
        });
      }
    }).catch((error) => {
      console.log("Phone confirmation error", error);
      this.setState({
        error: true
      });
    });
  }

  _planOnClick = (event) => {
    let plan = event.currentTarget.dataset.plan;

    this.setState({
      plan: plan
    });
  }

  _startSubscriptionOnClick = () => {
    this.setState({
      cancelledSubscription: true,
      step: 4,
      upgrade: false,
    });

    if (this.state.coupon) {
      this._applyCouponOnClick();
    }
  }

  _paymentInfoOnError = () => {
    this.setState({
      error: true,
      errorMessage: 'Please check your credit card details and try again.'
    });
  }

  _paymentInfoOnSuccess = async (token) => {

    let {
      email,
      plan,
      firstName,
      lastName,
      coupon,
      cancelledSubscription
    } = this.state;

    fetch('https://ngn105hybl.execute-api.us-west-2.amazonaws.com/prod/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentToken: token.id,
        email: email,
        plan: plan,
        firstName: firstName,
        lastName: lastName,
        coupon: (coupon ? coupon : null),
        skipTrial: cancelledSubscription
      }),
    }).then(response => response.json())
      .then(data => {
        if (data && data.type === 'success') {
          let expiresAt = new Date((Number(data.expiresAt) + (6 * 3600)) * 1000);
          //expiresAt.setDate(expiresAt.getDate() + 7);

          db.collection('users').doc(this.state.uid).set({
            subscription: {
              cancellationReason: null,
              cancelledAt: null,
              createdAt: new Date(),
              expirationIntent: null,
              expiresAt: expiresAt,
              productId: `co.juna.subscription.${this.state.plan === 'annually' ? 'yearly' : this.state.plan}`,
              purchasedAt: new Date(),
              source: "web",
              id: data.id
            }
          }, {
            merge: true
          }).then(() => {

          }).catch((error) => {

          });

          this.setState({
            step: 5
          });

          window._refersion(function () { window._rfsn._addCart(data.customerId); });

          /*
          gtag('event', 'click', {
            'event_category' : 'button',
            'event_label' : 'start free trial'
          });
          */
        } else {
          this.setState({
            error: true,
            errorMessage: data
          });
        }
      }).catch(error => {
        this.setState({
          error: true,
          errorMessage: 'Please check your credit card details and try again.'
        });
      });
  }

  _subscriptionOnCancel = (event) => {
    if (!window.confirm("Are you sure you want to cancel your subscription?")) {
      return false;
    }

    let userData = this.state.userData;

    fetch('https://ngn105hybl.execute-api.us-west-2.amazonaws.com/prod/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cancel: 1,
        subscriptionId: userData.subscription.id
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.type === 'success') {
          this.setState({
            subscriptionCancelled: true
          });

          let subscription = userData.subscription;
          subscription.cancelledAt = new Date();
          subscription.cancellationReason = 'customerCancelled';

          db.collection('users').doc(this.state.uid).set({
            subscription: subscription
          }, {
            merge: true
          }).then(() => {

          }).catch((error) => {

          });
        } else {
          alert("There was an error cancelling your subscription. Please try again or contact us at sarah@juna.co.");
        }
      })
      .catch(data => {
        alert("There was an error cancelling your subscription. Please try again or contact us at sarah@juna.co.");
      });
  }

  _applyCouponOnClick = () => {
    fetch('https://ngn105hybl.execute-api.us-west-2.amazonaws.com/prod/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        checkCoupon: 1,
        coupon: this.state.coupon
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.coupon) {
          this.setState({
            couponApplied: true
          });
        } else {
          alert("We couldn't find your coupon. Please try again or contact us at sarah@juna.co.");
        }
      })
      .catch(data => {
        alert("There was an error checking your coupon. Please try again or contact us at sarah@juna.co.");
      });
  }

  render() {

    const {
      firstName,
      lastName,
      step,
      hadBaby,
      isPregnant,
      isTTC,
      loading,
      login,
      coupon,
      couponApplied,
      phone,
      plan,
      plans,
      error,
      errorMessage,
      upgrade,
      userData,
      subscriptionCancelled,
      cancelledSubscription,
    } = this.state;

    return (<>
      <div className="header">
        <a href="https://juna.co/"> Juna </a>
      </div>
      <div className="steps">
        {loading &&
          <>
            Please wait...
          </>
        }
        {!loading &&
          <>
            {step === 1 &&
              <div className="step">
                <h2> Please tell us a little bit about you and your baby. </h2>
                <form className="form" onSubmit={this._infoOnClick}>
                  <fieldset>
                    <input type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="input"
                      required={true}
                      onChange={this._inputOnChange}
                    />
                  </fieldset>
                  <fieldset>
                    <input type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="input"
                      required={true}
                      onChange={this._inputOnChange}
                    />
                  </fieldset>
                  <fieldset>
                    <input type="email"
                      name="email"
                      placeholder="Email"
                      className="input"
                      required={true}
                      onChange={this._inputOnChange}
                    />
                  </fieldset>
                  <p style={{ margin: '15px 0 0 0' }}>Which statement best describes you?</p>
                  <fieldset className="spacious">
                    <p style={{ margin: '0 0 10px 0' }}>
                      <Switch on={isTTC} label="I'm trying to conceive" onClick={this._ttcOnClick} />
                    </p>
                    <p style={{ margin: '0 0 10px 0' }}>
                      <Switch on={isPregnant} label="I'm pregnant" onClick={this._pregnantOnClick} />
                    </p>
                    <p style={{ margin: '0 0 10px 0' }}>
                      <Switch on={hadBaby} label="I've already had my baby" onClick={this._hadBabyOnClick} />
                    </p>
                  </fieldset> {
                    (isPregnant || hadBaby) &&
                    <fieldset>
                      <label> {hadBaby ? "Baby's Birthday" : "Due Date"} </label>
                      <input type="date"
                        name="date"
                        className="input"
                        required={true}
                        onChange={this._inputOnChange}
                      />
                    </fieldset>
                  }

                  {isPregnant &&
                    <fieldset>
                      <p className="notice"> If you don't have the exact date yet, just enter a date that is closets to what you suspect. You can always change this date later.</p>
                    </fieldset>
                  }

                  {hadBaby &&
                    <fieldset>
                      <p className="notice"> Letting us know when you had your baby helps us give you the best nutrition and fitness program. </p>
                    </fieldset>
                  }

                  <fieldset className="spacious">
                    <button type="submit"
                      className="button"
                      disabled={!(isTTC || isPregnant || hadBaby)}> Continue </button>
                  </fieldset>
                </form>
              </div>
            }
            {step === 2 &&
              <div className="step">
                <h2> {login ? "Please enter your phone number" : "Next, we'll need to send you a verification code."} </h2>
                {error &&
                  <p className="notice error"> ERROR: There was an error. Please try again. </p>
                }

                <form className="form" onSubmit={this._phoneNumberOnSubmit}>
                  <fieldset>
                    <ReactPhoneInput
                      defaultCountry="us"
                      value={phone}
                      inputClass="input"
                      onChange={this._phoneNumberOnChange} />
                  </fieldset>
                  <fieldset>
                    <p className="notice"> Don't worry, Juna will never share your phone number with anyone else. We simply use it to verify your identity.</p> </fieldset> <fieldset className="spacious">
                    <button type="submit"
                      id="phone-number-submit"
                      className="button">Continue</button>
                  </fieldset>
                </form>
              </div>
            }
            {step === 3 &&
              <div className="step">
                <h2>Great, now enter the verification code we just sent to your mobile device.</h2>
                {error &&
                  <p className="notice error">ERROR: Invalid code. Please try again.</p>
                }
                <form className="form" onSubmit={this._verificationCodeOnSubmit}>
                  <fieldset>
                    <input type="text"
                      name="verificationCode"
                      placeholder="Verification code"
                      className="input"
                      onChange={this._inputOnChange}
                    />
                  </fieldset>
                  <fieldset className="spacious">
                    <button type="submit" className="button">Continue</button>
                  </fieldset>
                </form>
              </div>
            }
            {step === 4 &&
              <div className="step" onSubmit={this._paymentOnSubmit}>
                <h2>
                  {upgrade ? (
                    <>Upgrade Your Subscription</>
                  ) : (
                    <>
                      {cancelledSubscription ? 'Start Your Juna Subscription Today' : 'Start your Free trial of Juna Today'}
                    </>
                  )}
                </h2>
                <p> Guided workouts with week specific nutrition tips &amp; recipes created by Pre &amp; Post Natal experts for your pregnancy &amp; motherhood journey. </p>
                <h3> Choose Your Plan </h3>
                <div className="plans">
                  {!upgrade &&
                    <div data-plan="monthly" onClick={this._planOnClick} className={`plan${plan === 'monthly' ? ' plan--active' : ''}`}>
                      <div className="plan__price">${plans.monthly.price}</div>
                      <div className="plan__period">Monthly</div>
                    </div>
                  }

                  <div data-plan="quarterly" onClick={this._planOnClick} className={`plan${plan === 'quarterly' ? ' plan--active' : ''}`}>
                    <div className="plan__price">${plans.quarterly.price} </div>
                    <div className="plan__monthly"> ${plans.quarterly.monthly} / month</div>
                    <div className="plan__period">Quarterly </div>
                    <div className="plan__save">Save 33%</div>
                  </div>

                  <div data-plan="annually" onClick={this._planOnClick} className={`plan${plan === 'annually' ? ' plan--active' : ''}`}>
                    <div className="plan__price">${plans.annually.price} </div>
                    <div className="plan__monthly">${plans.annually.monthly} / month</div>
                    <div className="plan__period">Annually</div>
                    <div className="plan__save"> Save 40%</div>
                  </div>
                </div>
                {error &&
                  <p className="notice error">ERROR: {errorMessage}</p>
                }
                <p className="notice">Do you have a coupon? Enter it here:</p>
                <fieldset className="fieldset--coupon">
                  <input type="text"
                    name="coupon"
                    placeholder="Coupon code"
                    className="input"
                    value={this.state.coupon}
                    onChange={this._inputOnChange}
                  />
                  <button className="button button--light" disabled={coupon.length === 0} onClick={this._applyCouponOnClick}>Apply</button>
                </fieldset>
                {(coupon.length > 0 && couponApplied) &&
                  <div className="coupon_applied">
                    <p className="notice success">Your coupon has been applied!</p>
                  </div>
                }
                <p className="notice">Please enter your credit card details</p>
                <StripeProvider apiKey="pk_live_OIVQFIv29ZLAHIGr5bXoYKn100HEM0A9Fy">
                  <Elements>
                    <CheckoutForm
                      name={`${firstName} ${lastName}`}
                      onSuccess={this._paymentInfoOnSuccess}
                      onError={this._paymentInfoOnError}
                      trial={!this.cancelledSubscription}
                    />
                  </Elements>
                </StripeProvider>
                <div className="info">
                  <h4> Restore Purchases </h4>
                  <p> Payment will be charged to your credit card at the confirmation of purchase. The subscription automatically renews unless it is canceled at least 24 hours before the end of the current period. Your credit card will be charged
                    for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscriptions by visiting this page after purchase. </p>
                  <p> This subscription automatically renews
                    for ${plans[plan].price} {plan}
                    after the 7-day free trial. You can cancel anytime. By signing up
                    for a free trial, you agree to Juna's <a href="http://juna.co/terms-conditions/">Terms of Service</a> and <a href="http://juna.co/privacy-policy/">Privacy Policy</a>
                  </p>
                </div>
              </div>
            }
            {step === 5 &&
              <div className="step">
                <h2> Thank you! </h2>
                <p> Download the app from the App Store and log in using your phone number. </p>
                <p className="download">
                  <a href="https://itunes.apple.com/app/apple-store/id1452883313?pt=119669268&amp;ct=Web-Subscribe%20Page&amp;mt=8" target="_blank" rel="noopener noreferrer">
                    <img alt="App Store" src="/assets/appstore.png" />
                  </a>
                </p>
              </div>
            }
            {step === 6 &&
              <div className="step">
                <h2> Welcome {userData.firstName}! </h2>
                <h4> Your Juna Subscription </h4>
                <div className="subscription__info">
                  <p> Plan: <strong style={{ paddingRight: '10px' }}>{userData.subscription.productId.indexOf('yearly') > 0 ? 'Annual' : (userData.subscription.productId.indexOf('quarterly') > 0 ? 'Quarterly' : 'Monthly')} </strong>

                    {(userData.subscription.source === 'web' && userData.subscription.productId.indexOf('monthly') > 0) &&
                      <button onClick={this._upgradeOnClick} className="button button--light button--small">Upgrade</button>
                    }
                  </p>
                  {userData.subscription.expiresAt.seconds > Date.now() / 1000 && !userData.subscription.cancelledAt && !subscriptionCancelled &&
                    <>
                      <p>
                        Status: <strong> Active </strong>
                        {userData.subscription.source === 'web' &&
                          <a className="subscription__cancel" onClick={this._subscriptionOnCancel}> CANCEL </a>
                        }
                      </p>
                      <p>
                        {userData.subscription.source !== 'web' &&
                          <span> Please open the app on your phone to manage your subscription. </span>
                        }
                      </p>
                      <p> Renewal date: <strong> {
                        moment(userData.subscription.expiresAt.toDate()).format('MMM Do YYYY')
                      } </strong>
                      </p>
                    </>
                  }

                  {
                    (userData.subscription.expiresAt.seconds < Date.now() / 1000 || userData.subscription.cancelledAt || subscriptionCancelled) &&
                    <>
                      <p> Status: <strong> Canceled </strong></p>
                      <p> Valid until: <strong> {moment(userData.subscription.expiresAt.toDate()).format('MMM Do YYYY')} </strong></p>
                      <br />
                      <p> <button type="submit" className="button" onClick={this._startSubscriptionOnClick}> Start Your Subscription </button></p>
                      <br />
                    </>
                  }
                </div>
                <p className="download">
                  <a href="https://itunes.apple.com/app/apple-store/id1452883313?pt=119669268&amp;ct=Web-Subscribe%20Page&amp;mt=8" target="_blank" rel="noopener noreferrer">
                    <img alt="App Store" src="/assets/appstore.png" />
                  </a>
                </p>
              </div>
            } </>
        }
        <div ref={(ref) => this.recaptcha = ref}></div>
      </div>
    </>
    );
  }
}

export default App;