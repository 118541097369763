const firebaseConfig = {
  apiKey: "AIzaSyBoB6baPAX9olNg72FF9YbY6hCK-8aUPi4",
  authDomain: "juna-d8226.firebaseapp.com",
  databaseURL: "https://juna-d8226.firebaseio.com",
  projectId: "juna-d8226",
  storageBucket: "juna-d8226.appspot.com",
  messagingSenderId: "434158480288",
  appId: "1:434158480288:web:c68fa0bd8a9d1b96"
};

export default firebaseConfig;