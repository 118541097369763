import React, { Component } from 'react';
import './css/Switch.css';

export default class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      on: (this.props.on ? true : false)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.on !== this.props.on) {
      this.setState({
        on: this.props.on
      });
    }
  }

  _switchOnClick = (event) => {
    event.preventDefault();

    let on = !this.state.on;
    
    this.setState({
      on: on
    });

    this.props.onClick(on);
  }

  render() {
    const { on } = this.state;
    const { label } = this.props;

    return (
      <span className={`switch ${on ? 'switch--active' : ''}`} onClick={this._switchOnClick}>
        <span className="switch__indicator">
          <span className="switch__indicator__point"/>
        </span>
        {label &&
          <span className="switch__label">{label}</span>
        }
      </span>
    );
  }
}