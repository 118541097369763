import React, {Component} from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

class CheckoutForm extends Component {

  _formOnSubmit = async (event) => {
    let { token } = await this.props.stripe.createToken({
      name: this.props.name
    });

    if (token) {
      this.props.onSuccess(token);
    } else {
      this.props.onError();
    }
  }

  render() {
    return (
      <div>
        <CardElement />
        <div className="spacious" style={{ textAlign: 'center' }}>
          <button type="submit" className="button" onClick={this._formOnSubmit}>{this.props.trial ? 'Start Subscription' : 'Start Free 7 Day Trial'}</button>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);